// MUST IMPLEMENT FOLLOWING FUNCTION IF YOU NEED OPTIONS COMING FROM API CALL
// ALSO ATM YOU NEED TO ADD FUNCTIONS IN "forms.js" specifically "function fillOptions"

window.getOptions = function getOptions(step) {
    var api_call = getStepInput(step).attr('data-remote-url');
    switch (api_call) {
        case 'course_by_school_code':
            // step course is calling with step school_code as parameter
            return getOptionsStepCourseByScode()
        case 'discipline_by_course':
            // step discipline is calling with step course as parameter
            return getOptionsStepDisciplinebyCourse();
        case 'licences_by_course_and_discipline':
            // step licence is calling with step course and step discipline as parameter
            return getOptionsStepAdoptionByCourseAndDiscipline();
        case 'licences_by_course':
            // step licence is calling with step course as parameter
            return getOptionsStepAdoptionByCourse();
    }
};

function getSelectedSchoolCode() {
    return $('#organizations_support_teacher_activation_form_school_complex_code, #organizations_substitute_teacher_activation_form_school_complex_code').val();
}

function getSelectedCourseIds() {
    return $('#organizations_support_teacher_activation_form_course_ids, #organizations_substitute_teacher_activation_form_course_ids').val();
}

function getSelectedDisciplineIds() {
    return $('#organizations_support_teacher_activation_form_discipline_ids, #organizations_substitute_teacher_activation_form_discipline_ids').val();
}

function getSelectedLicenceIsbns() {
    return $('#organizations_support_teacher_activation_form_isbns, #organizations_substitute_teacher_activation_form_isbns').val();
}

// ajax call to get options

function getOptionsStepCourseByScode() {
    var school_code = getSelectedSchoolCode();
    return $.ajax({
        url: '/schools/licences/form_data/courses_with_adoptions_by_school_code/?school_code=' + school_code,
        dataType: 'json'
    });
}

function getOptionsStepDisciplinebyCourse() {
    var course_id = getSelectedCourseIds();
    return $.ajax({
        url: '/schools/licences/form_data/disciplines_with_adoptions_by_course_id/?course_id=' + course_id,
        dataType: 'json'
    });
}

function getOptionsStepAdoptionByCourseAndDiscipline(step) {
    var course_id = getSelectedCourseIds();
    var discipline_id = getSelectedDisciplineIds();
    return $.ajax({
        url: '/schools/licences/form_data/adoptions_by_course_id_and_discipline_id/?course_id=' + course_id + '&discipline_id=' + discipline_id,
        dataType: 'json',
    });
}

function getOptionsStepAdoptionByCourse(step) {
    var course_id = getSelectedCourseIds();
    return $.ajax({
        url: '/schools/licences/form_data/adoptions_by_course_id/?course_id=' + course_id,
        dataType: 'json',
    });
}

window.createLicenceRowHtml = function createLicenceRowHtml(option) {
    var volumes_count_text = option.volumes_count > 1 ? $('#volumes-count-text-plural').html() : $('#volumes-count-text-singular').html();
    var volumes = option.volumes.slice(1);
    var user_licences_isbns = ($('#user-licence-isbns').attr('data-user-licences') || '').split(',');
    var user_has_licence = user_licences_isbns.includes(option.isbn);
    var user_has_licence_class = user_has_licence ? 'checked' : '';
    var user_has_licence_message = $('#user-has-licence-message').html();
    var publisher_not_allowed_message = $('#publisher-not-allowed-message').html();
    var tooltip_text = user_has_licence ? user_has_licence_message : publisher_not_allowed_message;
    var licenceRowHtml = `
        <div class="licence-row ${user_has_licence ? 'has-licence' : ''}" data-isbn="${option.isbn}" data-allowed="${option.publisher_allows_activations && !user_has_licence}">
            <div class="licence-checkbox-container">
                <div class="licence-checkbox ${user_has_licence_class}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                        <rect x="1" y="1.641" width="16" height="16" rx="1" fill="none" stroke="#99A1AE" stroke-width="2"/>
                        <path d="m7.929 13.64-3.362-3.284a.775.775 0 0 1 1.082-1.11l2.28 2.222 5.418-5.295a.777.777 0 1 1 1.086 1.112z" fill="#fff"/>
                    </svg>
                </div>
                <div class="volumes-count">
                    ${option.volumes_count} ${volumes_count_text}
                </div>
            </div>
            <div class="expand-section" data-count="${option.volumes_count}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 16" fill="none">
                    <path d="M8.958 4.224a.75.75 0 1 0-1.5 0v3.375H4.083a.75.75 0 1 0 0 1.5h3.375v3.375a.75.75 0 1 0 1.5 0V9.099h3.375a.75.75 0 1 0 0-1.5H8.958z" fill="#1600A8"/>
                </svg>
            </div>
            <div class="not-allowed-message">
                <svg width="16" height="12" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2840_11389)">
                        <path d="M3.50586 9.53252C3.50586 9.11806 3.8393 8.78461 4.25377 8.78461H5.00168V6.79019H4.25377C3.8393 6.79019 3.50586 6.45675 3.50586 6.04228C3.50586 5.62781 3.8393 5.29437 4.25377 5.29437H5.74959C6.16405 5.29437 6.4975 5.62781 6.4975 6.04228V8.78461H6.7468C7.16127 8.78461 7.49471 9.11806 7.49471 9.53252C7.49471 9.94699 7.16127 10.2804 6.7468 10.2804H4.25377C3.8393 10.2804 3.50586 9.94699 3.50586 9.53252Z" fill="#EF3A71"/>
                        <path d="M6.20542 4.00508C6.0184 4.1921 5.76476 4.29716 5.50028 4.29716C5.23581 4.29716 4.98216 4.1921 4.79515 4.00508C4.60814 3.81807 4.50307 3.56442 4.50307 3.29995C4.50307 3.03547 4.60814 2.78182 4.79515 2.59481C4.98216 2.4078 5.23581 2.30273 5.50028 2.30273C5.76476 2.30273 6.0184 2.4078 6.20542 2.59481C6.39243 2.78182 6.4975 3.03547 6.4975 3.29995C6.4975 3.56442 6.39243 3.81807 6.20542 4.00508Z" fill="#EF3A71"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_2840_11389">
                            <rect width="11" height="11" fill="white" transform="translate(0 0.791504)"/>
                        </clipPath>
                    </defs>
                </svg>
                <span class="tooltip-text">${tooltip_text}</span>
            </div>
            <div class="licence-details">
                <div class="book-title">
                    ${option.book_title}
                </div>`;
    if (volumes) {
        licenceRowHtml += `
                <div class="volumes hidden">
        `;
        volumes.forEach(function(volume) {
            licenceRowHtml += `
                    <div class="book-title">
                        ${volume.title}
                    </div>
            `;
        });
        licenceRowHtml += `
                </div>
        `;
    }
    licenceRowHtml += `
                <div class="licence-description">
                    <div class="isbn">
                        ${option.isbn}
                    </div>
                    <div class="publisher">
                        ${option.publisher}
                    </div>
                </div>
            </div>
        </div>  
    `;
    return licenceRowHtml;
}
