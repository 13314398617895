$(document).ready(function () {
    var domains = $('.organization-domains').find('li');
    var email_input = $('#organizations_import_teacher_form_email');
    
    domains.on('click', function() {
        var domain = $(this).text();
        email_input.val(email_input.val() + domain);
        email_input.focus();
        email_input.trigger('change');
        email_input.trigger('input');
    });

    $('.date-icon').on('click', function() {
        // Simula un clic sull'input per aprire il calendario
        $(this).siblings('input[type=date]')[0].showPicker();
    });
});

window.Parsley
  .addValidator('validDomain', {
    requirementType: 'string',
    validateString: function(value, requirement) {
        var values = requirement.split(',');
        return values.includes(value.split("@")[1]);
    }
  });