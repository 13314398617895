$(document).ready(function () {
    
    var DatatableLanguageUrl = getDatatableLanguageUrl(get_locale());

    var default_options = {
        pageLength: 30,
        lengthChange: false,
        dom: 'lrtip',
        bInfo: false,
        scrollX: true,
        buttons: [
            {
                extend: 'csvHtml5',
                text: 'Download CSV',
                className: 'download-button',
                // add date to the csv file name in the format YYYYMMDD with no dash
                title: $(".download-button").data('filename') || `export_bSmartSchools_${new Date().toISOString().slice(0, 10)}`,
            }
        ],
        language: {
            url: DatatableLanguageUrl,
            oPaginate: {
                sFirst: "«",
                sPrevious: "‹",
                sNext: "›",
                sLast: "»"
            }
        }
    }

    // create variable action_options that is default_options with the columns key added
    var action_options = {
        ...default_options,
        columns: [
            {name: "Azioni", orderable: true},
            {name: "Azioni", orderable: true},
            {name: "Azioni", orderable: true},
            {name: "Azioni", orderable: false}
        ]
    }

    if ($('#dataTable').length) {
        var options = default_options;
        if ($('#dataTable').attr('data-table-options') == 'action_options') {
            options = action_options;
        }

        var table = $('#dataTable').DataTable(options);
        // show me the value of language.oPaginate option of the table
        console.log(table.settings()[0].oLanguage.oPaginate);

        $('.search-box input').on('keyup', function() {
            table.search(this.value).draw();
        });

        $('.download-button').on('click', function() {
            table.button('.buttons-csv').trigger();
        });

        $('.info-modal-button').on('click', function() {
            $('.info-modal').show();
        });
    }
});

function getDatatableLanguageUrl(locale) {
    var language_url = '';
    switch (locale) {
        case 'it':
            language_url = 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/Italian.json';
            break;
        case 'en':
            language_url = 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/English.json';
            break;
        case 'es':
            language_url = 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json';
            break;
        case 'fr':
            language_url = 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/French.json';
            break;
        case 'de':
            language_url = 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/German.json';
            break;
        default:
            language_url = 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/Italian.json';
    }
    return language_url;
}