$(document).ready(function () {

    var ParsleyLocale = get_locale();

    // INIT FORM
    var forms = $('.parsley-form');
    window.Parsley.setLocale(ParsleyLocale);
    forms.each(function() {
        var form = $(this);

        if (form.length == 0) {
            return;
        }

        form.parsley();

        var steps = getSteps(form);
        var firstStep = steps.first();
        $('.step-content').hide();
        getStepContent(firstStep).show();
        
        steps.each(function(index, step) {
            var step = $(step);
            var stepNumber = getStepNumber(step);
            var stepNavigation = getStepNavigation(step);
            var stepContent = getStepContent(step);
            var input = getStepInput(step);
            var inputId = input.attr('id');
            var submitButton = getStepSubmitButton(step);
        
            // add event listener to
            stepNavigation.on('click', function() {
                clickableStep(step) ? showStep(step) : null;
            });
        
            input.on('change keyup', function() {
                var isValid = checkStepValidity(step);
                resetAllStepsAfter(step);
                toggleSubmitButton(step, isValid);
            });

            submitButton.on('click', function(event) {
                event.preventDefault();
                clickSubmitButton(step);
            });
        });

        // Last submit button of the form must have [name='commit']
        form.find("input:submit[name='commit']").on('click', function(event) {
            event.preventDefault();
            form.submit();
        });
    });
});



function clickableStep(step) {
    var htmlContent = step.children('.step-content');
    return checkStepValidity(step) && !step.hasClass('active')
};

function showStep(step) {
    var htmlContent = step.children('.step-content');
    $('.step-content').slideUp(500);
    $('.form-step').removeClass('active');
    getStepContent(step).slideDown(500);
    step.addClass('active');
};

// old manageDisableBtn 
function checkStepValidity(step) {
    var form = getFormFromStep(step);
    var isValid = form.parsley().isValid({group: 'step_' + getStepNumber(step)});
    return isValid;
};

function getValidSteps(form) {
    var steps = getSteps(form);
    steps = steps.filter(function(index, step) {
        return checkStepValidity($(step));
    });
    return steps;
};

function resetAllStepsAfter(step) {
    var form = getFormFromStep(step);
    var steps = getSteps(form);
    var stepNumber = getStepNumber(step);
    steps.each(function(index, checkingStep) {
        var checkingStep = $(checkingStep);
        if (getStepNumber(checkingStep) > stepNumber) {
            var input = getStepInput(checkingStep);
            input.val('');
            toggleSubmitButton(checkingStep, false);
            toggleStepNavigation(checkingStep, false);
        }
    });
};

function toggleSubmitButton(step, isValid) {
    var submitButton = getStepSubmitButton(step);
    submitButton.prop('disabled', !isValid);
};

function clickSubmitButton(step) {
    var form = getFormFromStep(step);
    var validSteps = getValidSteps(form);
    var steps = getSteps(form);

    steps.each(function(index, checkingStep) {
        var checkingStep = $(checkingStep);
        if (jQuery.inArray(checkingStep[0],validSteps) == -1) {
            showStep(checkingStep);
            toggleStepNavigation(checkingStep, true);
            getStepInput(checkingStep).attr('data-remote-filled') == 'true' ? loadOptions(checkingStep) : null;
            return false;
        }
    });
};

function toggleStepNavigation(step, isValid) {
    var stepNavigation = getStepNavigation(step);
    isValid ? stepNavigation.addClass('filled') : stepNavigation.removeClass('filled');
}

function loadOptions(step) {
    getOptions(step).then(function(options) {
        fillOptions(step, options);
    }).catch(function(error) {
        console.error('An error occurred:', error);
    });
}

function fillOptions(step, options) {
    var input = getStepInput(step);
    var firstOption = input.find('option').first();
    input.empty();
    switch (getStepInputType(step)) {
        case 'simple-select':
            fillSimpleSelect(input, options, firstOption);
            break;
        case 'multiple-select':
            fillMultipleSelect(input, options, firstOption);
            break;
        case 'custom-licence-select':
            fillCustomLicenceSelect(input, options);
            break;
    }
}

// simple-select, multiple-select, custom-licence-select
function getStepInputType(step) {
    var input = getStepInput(step);
    return input.attr('data-type');
}

function fillSimpleSelect(input, options, firstOption) {
    input.append(firstOption);
    options.forEach(function(option) {
        input.append('<option value="' + option.value + '">' + option.text + '</option>');
    });
    input.trigger('updated');
}

function fillMultipleSelect(input, options, firstOption) {
    input.append(firstOption);
    options.forEach(function(option) {
        input.append('<option value="' + option.value + '">' + option.text + '</option>');
    });
    input.trigger('updated');
}

function fillCustomLicenceSelect(input, options) {
    var licenceRowsContainer = $('#licence-rows-container');
    licenceRowsContainer.empty();

    options.forEach(function(option) {
        var licenceRowHtml = createLicenceRowHtml(option)
        licenceRowsContainer.append(licenceRowHtml);
    });
    handleCustomLicenceSelect(licenceRowsContainer);
}

function handleCustomLicenceSelect(licenceRowsContainer) {
    var licenceRows =  licenceRowsContainer.children('.licence-row');
    licenceRows.find('.volumes').slideUp(0); // hide all volumes

    licenceRows.each(function(index, licenceRow) {
        var licenceRow = $(licenceRow);

        var isClickable = licenceRow.attr('data-allowed');
        if (!isClickable || isClickable != 'true') { return; }

        // expand section handling
        var expandSection = licenceRow.children('.expand-section');
        var volumes = licenceRow.find('.volumes');
        expandSection.on('click', function() {
            handleExpandSectionClick(expandSection, volumes);
        });

        // licence checkbox handling
        var licenceCheckbox = licenceRow.find('.licence-checkbox');
        licenceCheckbox.on('click', function() {
            handleLicenceCheckboxClick(licenceCheckbox);
        });
        licenceCheckbox.trigger('click');
    });
}

function handleLicenceCheckboxClick(licenceCheckbox) {
    var originalSelect = $('.custom-licence-select');
    var licenceRow = licenceCheckbox.closest('.licence-row');
    var isbn = licenceRow.attr('data-isbn');
    var isChecked = licenceCheckbox.hasClass('checked');
    isChecked ? licenceCheckbox.removeClass('checked') : licenceCheckbox.addClass('checked');
    if (isChecked) {
        originalSelect.find('option[value="' + isbn + '"]').remove();
    } else {
        originalSelect.append('<option value="' + isbn + '"></option>');
    }
    originalSelect.children('option').prop('selected', true);
    originalSelect.trigger('change');
}


function handleExpandSectionClick(expandSection, volumes) {
    volumes.hasClass('open') ? volumes.removeClass('open') : volumes.addClass('open');
    volumes.hasClass('open') ? volumes.slideDown(200) : volumes.slideUp(200);
    // change icon on expand section by changing the d attrubute of the path of the svg from "M8.958 4.224a.75.75 0 1 0-1.5 0v3.375H4.083a.75.75 0 1 0 0 1.5h3.375v3.375a.75.75 0 1 0 1.5 0V9.099h3.375a.75.75 0 1 0 0-1.5H8.958z" to "M7.458 8.1v-.012h1.5V8.1h3.375a.75.75 0 1 1 0 1.5h-.889v-.028H4.557V9.6h-.474a.75.75 0 1 1 0-1.5z"
    var plusIconPath = "M8.958 4.224a.75.75 0 1 0-1.5 0v3.375H4.083a.75.75 0 1 0 0 1.5h3.375v3.375a.75.75 0 1 0 1.5 0V9.099h3.375a.75.75 0 1 0 0-1.5H8.958z";
    var minusIconPath = "M7.458 8.1v-.012h1.5V8.1h3.375a.75.75 0 1 1 0 1.5h-.889v-.028H4.557V9.6h-.474a.75.75 0 1 1 0-1.5z";
    var path = expandSection.children('svg').children('path');
    volumes.hasClass('open') ? path.attr('d', minusIconPath) : path.attr('d', plusIconPath);
}

// getters

function getSteps(form) {
    return form.find('.steps').children('.form-step');
}

function getNextStep(step) {
    var steps = getSteps(getFormFromStep(step));
    var nextStep = steps[getStepNumber(step)];
    return $(nextStep);
}

function getPreviousStep(step) {
    var steps = getSteps(getFormFromStep(step));
    var previousStep = steps[getStepNumber(step) - 2];
    return $(previousStep);
}

function getFormFromStep(step) {
    return step.closest('.parsley-form');
}

function getStepNumber(step) {
    return step.attr('class').split(' ')[1].split('-')[1];
}

function getStepNavigation(step) {
    return step.children('.step-header');
}

function getStepContent(step) {
    return step.children('.step-content');
}

window.getStepInput = function getStepInput(step) {
    return step.find('select, input:not([type="submit"])');
}

function getStepSubmitButton(step) {
    return step.find('input[type="submit"]');
}